import styled from "@emotion/styled"
import { device } from "../styles/device"
import { colors } from "../styles/colors"
import { fontFamily } from "../styles/fonts/fontFamily"

const H1 = styled.h1`
    font-size: 40px;
    margin: 75px 0;
    color: ${colors.black};
    max-width: 900px;
    line-height: 1.15;
    z-index: 9;
    font-family: ${fontFamily.manrope};
    font-weight: 600;
    letter-spacing: -0.09rem;

    a {
        text-decoration: none;
        color: ${colors.purple};
    }

    @media ${device.tablet} {
        font-size: 80px;
        margin: 150px 0;
        max-width: 100%;
    }
`

export default H1
